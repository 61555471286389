
import { Watch, Mixins, Component, Ref, InjectReactive } from 'vue-property-decorator'
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import SaaSServiceBasicList from "./basicList.vue";

@Component({
    components: { SaaSServiceBasicList }
})
export default class SaaSService extends Mixins(loading, tablePagination, Resize) {
    @InjectReactive() activeName!: string|number;
    @InjectReactive() productCategoryOptions!: Array<any>;

    @Ref("SaaSServiceBasicListRef") readonly SaaSServiceBasicListRef !: SaaSServiceBasicList;
    storeId = 0;
    

    mounted() {
        this.SaaSServiceBasicListRef.handleSearch();
    }
}

