
import { PropSync, Watch, Prop, Ref, Component, Vue, InjectReactive, Emit } from 'vue-property-decorator';
import { presCategoryTypeList, saasServiceList, saasServiceProduct } from "@/api/prescriptionDb";

@Component({
  components: {
  },
})
export default class SaaSServiceForm extends Vue {
    @Ref("form") formRef: VForm;
    @Prop({ default: 0 }) readonly storeId !: number;
    @PropSync("form") dialogForm!: any;
    @PropSync("visible") dialogVisible !: boolean;
    @InjectReactive() productCategoryOptions!: Array<any>;

    productNameOptions = [];
    saasServiceAllOptions = [];
    saasServiceOptions = [];
    saasServiceProductOptions = [];

    saveLoading = false;
    formRules = {
        presCategoryId: { required: true, message: "请选择产品分类" },
        presId: { required: true, message: "请选择产品名称" },
        saasCategoryId: { required: true, message: "请选择SaaS服务分类" },
        saasServiceId: { required: true, message: "请选择SaaS服务产品" },
    };

    mounted() {
        // this.getProductCategoryOptions();
        this.initSaasServiceList();
    }

    @Watch("dialogForm.saasCategoryId")
    setSaasServiceProductOptions(categoryId) {
        this.saasServiceProductOptions = this.saasServiceAllOptions?.filter((el) => {
            return el.saasServiceClassId == categoryId;
        })
    }

    @Watch("dialogForm.presCategoryId")
    setProductOptions(presCategoryId) {
       if (!presCategoryId) return;
       this.getProductOptions()
    }

    @Watch("dialogVisible")
    initSaasServiceList() {
        if (!this.dialogVisible) return;
        saasServiceList({storeId: this.storeId}).then((res) => {
            const list = res.data;
            this.saasServiceAllOptions = list;
            const _list = _.uniqBy(list, "saasServiceClassId");
            this.saasServiceOptions = _list;
        }).catch(() => {

        })
    }

    get getDialogTitle() {
        return "新增/编辑"
    }

    handleClose() {
        this.formRef.resetFields();
        this.dialogForm.id = "";
        this.productNameOptions = [];
        this.dialogVisible = false;
    }

    getProductOptions() {
        const params = {
            categoryType: 2,
            parentId: this.dialogForm.presCategoryId,
            merchantId: 0,
        }
        presCategoryTypeList(params).then((res) => {
            this.productNameOptions = res.data;
        })
    }

    getProductCategoryOptions() {
        const params = {
            categoryType: 1,
            parentId: 1,
            merchantId: 0,
        }
        presCategoryTypeList(params).then((res) => {
            this.productCategoryOptions = res.data;
        })
    }

    fenleiChange($event) {
        this.dialogForm.presId = ""
    }

    mingchengChange() {
    }
    mingchengFocus() {
        this.getProductOptions();
    }

    saasfenleiChange() {
        this.dialogForm.saasServiceId = "";
    }

    @Emit("syncList")
    syncList() {
        return this.storeId;
    }

    submit() {
        this.formRef.validate().then(() => {
            this.saveLoading = true;
            const { saasCategoryId, saasServiceId } = this.dialogForm;
            const saasCategoryName = this.saasServiceOptions?.find(el => el.saasServiceClassId == saasCategoryId)?.saasServiceClassName;
            const saasServiceName = this.saasServiceProductOptions?.find(el => el.saasServiceId == saasServiceId)?.saasServiceName;
            const params = { 
                ...this.dialogForm, saasCategoryName, saasServiceName,
                storeId: this.storeId
            }
            saasServiceProduct(params).then((res) => {
                this.$message.success("操作成功")
                this.handleClose();
                this.syncList();
            }).catch(() => {
            }).finally(() => {
                this.saveLoading = false;
            })
        })
    }

}
