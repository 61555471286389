
  import { Watch, Mixins, Component, Vue, Ref, Provide, ProvideReactive } from "vue-property-decorator";

  import TablePagination from "@/mixins/tablePagination";
  import Resize from "@/mixins/resize";

  import SaasServiceCommon from "./components/common.vue";
  import SaasServiceSingle from "./components/single.vue";

  import { presCategoryTypeList } from "@/api/prescriptionDb";
  
  @Component({
    inject: [],
    components: {
        SaasServiceCommon,
        SaasServiceSingle
    }
  })
  export default class SaasServiceConfig extends Mixins(Resize, TablePagination) {
    @ProvideReactive()
    activeName = "first";
    @Ref("SaasServiceSingle") readonly SaasServiceSingleRef!: SaasServiceSingle;

    detectionTotal = [
        { quantity: 0, label1: "舌诊剩余可调用量", label2: "" },
        { quantity: 0, label1: "舌诊总分配次数", label2: "（含小程序/门店）" },
        { quantity: 0, label1: "舌诊总检测次数", label2: "（含小程序/门店）" },
        { quantity: 0, label1: "舌诊总购买次数", label2: "（小程序）" },
    ]

    @ProvideReactive()
    productCategoryOptions = [];

    mounted() {
        this.getProductCategoryOptions();
    }

    getProductCategoryOptions() {
        const params = {
            categoryType: 1,
            parentId: 1,
            merchantId: 0,
        }
        presCategoryTypeList(params).then((res) => {
            this.productCategoryOptions = res.data;
        })
    }

    // addServiceConfigWithStore() {
    //     this.SaasServiceSingleRef?.handleEdit({});
    // }
  }
