
import { Watch, Mixins, Component, Vue, ProvideReactive, InjectReactive, Prop } from 'vue-property-decorator'
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";
import SaaSServiceForm from "./form.vue";

import { presCategoryTypeList, saasServiceProductList, saasServiceProductDel } from "@/api/prescriptionDb";

@Component({
    components: { ETable, SaaSServiceForm }
})
export default class SaaSServiceBasicList extends Mixins(loading, tablePagination, Resize) {
    @Prop({ default: 0 }) readonly storeId !: number;
    @InjectReactive() activeName!: string|number;

    @InjectReactive() productCategoryOptions!: Array<any>;

    formVisible = false;
    form = {
        presCategoryId: "",
        presId: "",
        saasCategoryId: "",
        saasServiceId: "",
        remark: "",
    };

    queryForm = {
        productClassId: "",
        productName: "",
        saasServiceName: ""
    }

    tableData = [];
    columns = [
        { label: "产品分类", prop: "productClassName" },
        { label: "产品名称", prop: "productName" },
        { label: "SAAS服务分类", prop: "saasCategoryName" },
        { label: "SAAS服务名称", prop: "saasServiceName" },
        { label: "备注", prop: "remark" },
        { label: "操作人", prop: "createdBy" },
        { label: "最后操作时间", prop: "createdTime" },
        { label: "操作", prop: "action" },
    ]

    mounted() {
        this.windowResize(320)
    }

    resetForm() {
        Object.assign(this.queryForm, {  
            productClassId: "",
            productName: "",
            saasServiceName: ""
        })
    }

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }
    handleEdit(row) {
        this.formVisible = true;
        this.$nextTick(() => {
            if (row.id) {
                const { id, presCategoryId, presId, saasCategoryId, saasServiceId, remark } = row;
                Object.assign(this.form, { id, presCategoryId, presId, saasCategoryId, saasServiceId, remark })
            }
        })
    }
    handleDel(row) {
        this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            saasServiceProductDel({ id: row.id }).then((res) => {
                this.$message.success("操作成功");
                this.getData();
            })
        })
    }
    getData() {
        this.showLoading();
        const params = {
            ...this.queryForm,
            productClassId: this.queryForm.productClassId || undefined,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            storeId: this.storeId
        }
        saasServiceProductList(params).then((res) => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        })
    }

    @Watch("storeId")
    reloadDataList() {
        if (this.activeName !== "second") return;
        this.getData()
    }
}

