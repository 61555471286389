
import { Watch,Mixins,Component,InjectReactive, Ref } from "vue-property-decorator";
import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";

import { cloneDeep } from "lodash";

import { StoreStatusEnum } from "@/enum/store.enum";

import { saasStoreList, saasServiceDisable } from "@/api/prescriptionDb";

import SaaSServiceBasicList from "./basicList.vue";


@Component({
    components: {
        SaaSServiceBasicList
    }
})
export default class BookingConfig extends Mixins(TablePagination,Resize) {
    @Ref("SaaSServiceBasicListRef") readonly SaaSServiceBasicListRef !: SaaSServiceBasicList;
    @InjectReactive() activeName!: string | number;

    @InjectReactive() productCategoryOptions!: Array<any>;

    storeStatusEnum = StoreStatusEnum;

    storeName = "";
    storeId = 0;
    selectStoreInfo: any = {};

    openFlag = false;

    allStoreList: Array<any> = [];
    storeList: Array<any> = [];

    loading = false;

    saveLoading = false;

    formVisible = false;
    form = {
        presCategoryId: "",
        presId: "",
        saasCategoryId: "",
        saasServiceId: "",
        remark: "",
    };

    mounted() {
        this.windowResize(500);
    }

    getStoreList() {
        saasStoreList({ storeName: "" }).then((res) => {
            res.data?.forEach((el) => {
                el.id = el.storeId;
            })
            this.allStoreList = res.data;
            this.storeList = res.data;
            this.selectStoreInfo = this.storeList[0];
            this.storeId = this.selectStoreInfo.id;
            this.openFlag = Boolean(this.selectStoreInfo.saasStoreProductSwitch);
            this.getData()
        })
    }


    syncStoreConfig() {
        this.resetCurrent();
        this.getData();
    }

    selectStore(store) {
        this.storeId = store.id;
        this.selectStoreInfo = store;
        this.openFlag = Boolean(store.saasStoreProductSwitch);
        this.SaaSServiceBasicListRef?.resetForm();
        this.getData();
    }

    switchClick() {
        this.$confirm(`是否${this.openFlag ? "关闭" : "开启"}自定义开关 ?`,"提示",{
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            const params = { storeId: this.storeId, state: Number(!this.openFlag) }
            saasServiceDisable(params).then((res: any) => {
                this.$message.success("操作成功");
                this.openFlag = !this.openFlag;
                this.selectStoreInfo.saasStoreProductSwitch = this.openFlag;
            });
        });
    }

    @Watch("storeName",{ immediate: true })
    filterStoreList(value: string) {
        if (!value) {
            this.storeList = cloneDeep(this.allStoreList);
            return;
        }
        const _list = this.allStoreList?.filter(el => el.storeName.toLocaleUpperCase().indexOf(value?.trim()?.toLocaleUpperCase()) > -1)
        this.storeList = cloneDeep(_list);
    }

    @Watch("activeName",{ immediate: true })
    getStoreListData() {
        if (this.activeName != "second") return;
        this.storeName = "";
        this.getStoreList();
    }
}

